@import "./style/commonColor.less";
body {
    font-family: "Interstate",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5;
    font-size: 1rem;
}
.relative {
    position: relative;
}
.hoverBorder {
    border: 2px solid transparent;
    transition: border-color .3s;
}
.badge{
    font-weight: normal;
    padding: 5px 20px;
    margin-right: 10px;
    transition-duration: .2s;
}
.fifty {
    height: 50px;
}
.left {
    float: left;
}
.right {
    float: right;
}
.inline-block{
    display: inline-block;
}
.center {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}
.badge.badge-primary:hover {
    background: @Base-3;
}
.badge.badge-primary{
    background: @Base-2
}
.clear-btn-style{
    border-color: transparent;
    background-color: transparent;
    padding: 0px !important;
    margin: 0px;
}
.clear-a-style{
    padding: 0px !important;
}

.by-button.by-bluefill{
    border-color:rgb(247, 148, 30) !important;
    background: rgb(247, 148, 30) !important;

    &:hover{
        background: white !important;
        border-color: rgb(247, 148, 30) !important;
        color: rgb(247, 148, 30) !important;
    }
}

.blueberry .by-button.by-xl.cis-home-viewIndices{
    line-height: 1.5;
    font-size: 14px;
}

.cis-home-buttonbar-container .by-button-default{
    font-size: 12px;
}

.cis-home-grid-controller .w27p{
    width: 27%
}

.cis-home-grid-controller .w27p input{
    height: 30px
}