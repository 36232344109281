@import "../../style/commonColor.less";

.Menu .helpBar .helpList .helpItem .getAccessIcon {
    margin-right: 8px;
}

.Menu .menuBar {
    height: 50px;
    display: flex;
    align-items: center;
    background: @backgroundColor;
    border-bottom: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
}

.Menu .menuBar .menuList {
    margin: 0;
    padding: 0;
    float: left;
}

.Menu .menuBar .menuList li {
    display: inline-block;
    margin-right: 40px;
}

.Menu .menuBar .menuList li a {
    color: @Link-1;
}

.Menu .menuBar .menuList li a:hover {
    color: @Link-3;
}

.Menu .menuBar .searchItem {
    float: right;
}

.Menu .menuBar .searchItem:hover {
    cursor: pointer;
}

.Menu .menuBar .searchItem .searchIcon {
    height: 16px;
    width: 16px;
    margin-right: 8px;
}

.Menu .menuBar .searchItem .searchLabel {
    color: @Link-1;
}

.Menu .menuBar .searchItem:hover .searchLabel {
    color: @Link-3;
}

.Menu .menuCollapses {
    background-color: @Link-1;
    border-top: 1px solid @backgroundColor;
    position: absolute;
    width: 100%;
    z-index: 16;
}

.Menu .menuCollapses .MenuList {
    padding: 20px 35px;
    margin: 0;
    list-style: none;
}

.Menu .menuCollapses .MenuList .menuItem {
    padding: 16px;
}

.Menu .menuCollapses .MenuList .menuItem a,
.Menu .menuCollapses .MenuList .menuItem span {
    color: @backgroundColor;
    opacity: .8;
}

.Menu .menuCollapses .MenuList .mainMenu a {
    opacity: 1;
}

.Menu .menuCollapses .MenuList .divider {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin: 10px 0;
    padding: 0;
}

.Menu .toggleContent-closed {
    display: none;
}

.Menu .toggleContent-open {
    display: block;
    /* background-color: rgba(0,45,115,0.8); */
}

.Menu .dropdown-menu {
    right: 0;
    left: auto;
}

.Menu .helpBar .dropdown-menu a.retailDropDownItem {
    color: @Base-4;
    margin-left: 0;
    line-height: 34px;
    display: block;
    padding: 3px 20px;
    white-space: nowrap;
}

.Menu .helpBar .dropDownUl {
    width: 240px;
}

.Menu .dropdown-menu a .title {
    font-size: 1rem;
    line-height: 1.2em;
    display: block;
    width: 200px;
    white-space: normal;
    padding: 10px 0px;
}

.Menu sup {
    top: -.5em;
}

.Menu .dropdown-menu a .desc {
    font-size: 0.7rem;
    line-height: 1.2em;
    display: block;
    width: 200px;
    white-space: normal;
}

.Menu .menuBar a:link {
    text-decoration: none;
}

@media (max-width: 767.98px) {
    .Menu .helpBar {
        display: none;
    }

    .Menu .menuBar {
        display: none;
    }

    .Menu .logoBarFloating {
        height: 60px;
    }

    .Menu .logoBar {
        height: 60px;
        text-align: center;
        width: 100%;
        z-index: 100;
    }

    .logoBar.fixed {
        position: fixed;
        top: 0;
    }

    .Menu .logoBar .toggleBtn {
        display: block;
    }

    .Menu .logoBar .logo {
        float: none;
        height: 40px;
    }

    .Menu .logoBar .brand {
        display: none;
    }
}

@media (min-width: 768px) {
    .Menu .MenuList {
        display: none;
    }
}

.Menu .subMenu .subMenuItem {
    color: #fff;
    opacity: .8;
}

.Menu .subMenu li {
    list-style: none;
}

.logoBar .casLogo {
    text-decoration: none;
}

@media (max-width: 375px) {
    .Menu .subMenu .subMenuItem {
        display: block;
        padding: 6px 0;
        line-height: 1.1em;
    }
}

.Menu .ant-affix {
    z-index: 9999;
    width: 100% !important;
}

.cisText {
    margin: 0;
    font-weight: bold;

    a.cisLink {
        font-weight: normal;
        color:#007bff;

        &:hover {
            text-decoration: underline;
        }
    }
}
