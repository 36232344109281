
.cisIndicesBanner{
    text-align: center;
}
.cisIndicesBanner .playIcon{
       z-index: 1;
       width: 100px;
       height: 100px;
       position: absolute;
       /* left: 50%; */
       top: 50%;
       transform: translate(-50%, -50%);
       cursor: pointer;
   }
   .hidden-medias{
       display: none
   }
   .cis-medias{
    position: relative;
    width: 1200px;
    display: inline-block;
}

.imgContainer{
   width: 1200px;
   max-height: 600px
}
.detail-video-hidden{
   display: none
}


@media (max-width: 768px) {
   .cis-medias .playIcon{
       width: 70px;
       height: 70px;
   }
}

@media (max-width: 576px) {
   .cis-medias .playIcon{
       width: 50px;
       height: 50px;
   } 
}
.video-link{
    color: #07c;
}
.video-link:hover{
    color: #17355f;
}