.show-modal {
    display: flex; 
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto; 
    overflow-y: overlay;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
    justify-content: center;
    z-index: 10000;
  }
  .hidden-modal {
    display: none; 
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
    
  }
  
 
  .modal-content-incis {
    position: relative;
    background-color: #fefefe;
    border: 1px solid #888;
    max-width: 80%; 
    flex:0 0 80%;
    display: block;
    height: calc(100% - 24px);
  }

  .close {
    float: right;
    margin-right: 10px
  }
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .video{
      width: 100%
  }
  .iframe-cis-video{
    min-height: 100%;
    min-width: 100%;
  }
  .legal-icon-wrapper {
    position: absolute;
    right: 10px;
    top: 33px;
    color: #fff;
    cursor: pointer;
    z-index: 10001;
  }
  .legal-info {
     position: absolute;
     left: 0px;
     top: 24px;
     color: #f5f8fa;
     font-size: 14px;
     text-align: left;
     background-color: #000;
  }
  .legal-info__header {
    padding: 10px;
  }
  .legal-info__title {
    font-size: 18px;
    font-weight: normal;
  }
  .legal-info__body {
    padding: 5px 10px;
  }
  .legal-info_field {
    color: #a0bdd0;
  }
  .legal-info__text {
    word-wrap: break-word;
  }

  
