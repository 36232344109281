.GridCol {
    width: 100%;
    height: 70px;
    border: 1px dotted #686868;
    text-align: center;
    opacity: .5;
}
.GridCol:hover {
    opacity: 1;
    cursor: pointer;
}

.GridRow .container-fluid .portlet {
    padding-right:0px;
    padding-left: 0px;     
}
