/* reset */
body{
  margin-left: 0 !important;
}
iframe{
    width: 100% !important;
    border: 0 !important;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}
.insightCarousel .container-fluid .row{
  height: 100%;
}
div.insightCarousel div.owl-dots{
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: center;
}
div.insightCarousel .owl-nav .owl-prev{
  top: 50%;
  left: 20px;
}
div.insightCarousel .owl-nav .owl-next{
  top: 50%;
  right: 20px;
}
div.insightCarousel div.owl-dots .owl-dot.active span {
  background-color: #fff;
  opacity: 1;
}
div.insightCarousel div.owl-dots .owl-dot:hover span {
  background-color: #fff;
  opacity: 1;
}
div.insightCarousel div.owl-dots .owl-dot span {
  background-color: #fff;
  opacity: .5;
}
@font-face {
    font-family:"Interstate";
    src:url(/cis/akpublic/libs/font/1.woff) format("woff");
    font-style:normal;
    font-weight:200;
    font-display:fallback;
  }
    
  @font-face {
    font-family:"Interstate";
    src:url(/cis/akpublic/libs/font/2.woff) format("woff");
    font-style:normal;
    font-weight:400;
    font-display:fallback;
  }
    
  @font-face {
    font-family:"Interstate";
    src:url(/cis/akpublic/libs/font/3.woff) format("woff");
    font-style:normal;
    font-weight:700;
    font-display:fallback;
  }

  .react-grid-Container {
    max-width: 1190px !important; 
}
.composition-detail-page .react-grid-Container {
  max-width: 1200px !important; 
}

.react-grid-Canvas {
  width: 1190px !important; 
  overflow-x:hidden !important;
}
.composition-detail-page .react-grid-Canvas{
  width: 1200px !important; 
}
.react-grid-HeaderRow{
  left: -3px !important;
}
.pd-f-5{
  padding-left: 5px
}

.cis-news-desc{
  line-height: 15px
}

.cis-indices-realted{
  box-sizing: content-box
}

.cis-page-container .cis-filter .by-dropmenu-options .by-item .by-svg-add-border{
  color:#fff;
  border:1px solid #07c
}

.blueberry{
  font-family: "Interstate", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.blueberry .by-button, .blueberry .by-tooltip{
  font-family: "Interstate", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.cis-page-title,.cis-page-subTitle{
  font-family: "Interstate", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.blueberry .by-table .by-header .by-col, .blueberry .by-table-metro .by-header .by-col, .blueberry .by-text, .blueberry .by-button-bar {
  font-family: "Interstate", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.blueberry .by-dropmenu, .blueberry .by-input-info .by-input-error-message, .blueberry .by-link, .blueberry .by-table .by-footer .by-col, .blueberry .by-table .by-header .by-col, .blueberry .by-table-metro .by-header .by-col, .blueberry .by-text{
  font-family: "Interstate", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

body {
  margin: 0;
  font-family: Interstate, sans-serif;
  font-weight: 100;
  color: #53565a;
  margin-left: calc(100vw - 100%);
  height: 100%;
  /* user-select:none;
  -ms-user-select: none; */
}

/* section{
  margin:10px 5px;
} */

.blueberry {
  font-family: Interstate, sans-serif;
  font-weight: 100;
}

.blueberry .bc-icon-0{
  display:inline-block;
  position: relative;
  vertical-align:middle;
  fill:currentColor;
  stroke:none;
}


.cis-page {
  width: 1200px;
  margin: auto;
  max-width: 100%;
}

html {
  height: 100%;
}

[data-reactroot] {
  height: 100%;
}

.page-design {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.subPage-design {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.subPage-design-body {
  flex: 1 0 auto;
}

.page-design-body {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.vtalign-m{
  vertical-align: middle;
}

.cursor-pointer{ 
  cursor:pointer;
}

.disnone{
  display:none;
}

.w50p {
  width: 50%;
}

.w100 {
  width: 100px;
}

.w150 {
  width: 150px;
}

.w200 {
  width: 200px;
}

.max-w250 {
  max-width: 250px !important;
}

.w350 {
  width: 350px;
}

.w500 {
  width: 500px;
}

.w800 {
  width: 800px;
}

.h18 {
  height: 18px;
}

.h20 {
  height: 20px;
}

.h30 {
  height: 30px;
}

.h50 {
  height: 50px;
}

.pl5 {
  padding-left: 5px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pr {
  position: relative;
}

.w100p {
  width: 100%;
}

.min-h-250 {
  min-height: 250px;
}

.pr20 {
  padding-right: 20px;
}

.pr10 {
  padding-right: 10px;
}

.pr5 {
  padding-right: 5px;
}

.mgr-33 {
  margin-right: 33px;
}

.mgl-5 {
  margin-left: 5px;
}

.mgl-10 {
  margin-left: 10px !important;
}

.mgl-11 {
  margin-left: 11px !important;
}

.mgl-12 {
  margin-left: 12px !important;
}

.mgl-13 {
  margin-left: 13px !important;
}

.mgl-15 {
  margin-left: 15px !important;
}

.mgl-14 {
  margin-left: 14px;
}

.mgl-18 {
  margin-left: 18px !important;
}

.mgl-19 {
  margin-left: 19px;
}

.mgl-22 {
  margin-left: 22px !important;
}

.mgl-23 {
  margin-left: 23px !important;
}

.mgl-24 {
  margin-left: 24px !important;
}

.mgl-25 {
  margin-left: 25px !important;
}

.mgl-26 {
  margin-left: 26px !important;
}

.mgl-32 {
  margin-left: 32px !important;
}

.mgl-34 {
  margin-left: 34px !important;
}

.mgl-42 {
  margin-left: 42px !important;
}

.mgl-43 {
  margin-left: 43px !important;
}

.mgl-45 {
  margin-left: 45px !important;
}

.mgl-46 {
  margin-left: 46px !important;
}

.mgl-47 {
  margin-left: 47px !important;
}

.mgl-51 {
  margin-left: 51px !important;
}

.mgl-52 {
  margin-left: 52px !important;
}

.mgl-53 {
  margin-left: 53px !important;
}

.mgl-220 {
  margin-left: 220px;
}

.mgt-10 {
  margin-top: 10px;
}

.mgt-12 {
  margin-top: 12px;
}

.mgt-15 {
  margin-top: 15px;
}

.mgt-25 {
  margin-top: 25px;
}

.mgt-20 {
  margin-top: 20px;
}

.mgb-10 {
  margin-bottom: 10px;
}

.text-right{
  text-align: right;
}

.d-f{
  display: flex;
}

.d-b{
  display: block;
}

.d-n{
  display: none;
}

.ib {
  display: inline-block;
}

.pr {
  position: relative;
}

.display-inline{
  display: inline !important;
}

.padding-27{
  padding: 27px !important;
}

.font-size-16{
  font-size: 16px !important;
}

.font-size-15{
  font-size: 15px !important;
}

.cis-footer ul {
  border-top: 1px solid #bbb;
  height: 40px;
  padding: 10px;
  color: #53565a;
  font-size: 11px;
  box-sizing: border-box;
  margin:0;
}

.cis-footer li {
  height: 20px;
  line-height: 20px;
  list-style: none;
  float: right;
  margin-left: 10px;
}

.cis-footer li:first-child {
  float: left;
  margin-left: 0;
}

.cis-footer .text-decoration-a a {
  text-decoration: none;
  color: #53565a;
}

.cis-footer .text-decoration-a a:hover {
  text-decoration: underline;
  color: #53565a;
}

.cis-footer-bottomContent {
  display: inline-block;
  padding-left: 2px
}

.by-gradient-citi-bluewave .cis-header-text {
  display: inline-block;
}

.cis-header-text {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  color: #53565a;
}

.display-table {
  display: table;
}

.display-tableCell {
  display: table-cell;
}


.zIndex1 {
  z-index: 1;
}

.zIndex2 {
  z-index: 2;
}

.zIndex3 {
  z-index: 3;
}

.loader {
  color: #ffffff;
  font-size: 20px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.change-date-popup-style {
  background-color: lightblue;
  width: 300px;
  height: 150px;
  border-radius: 30px;
}

.p10-0 {
  padding: 10px 0;
}

.h20{
  height:20px;
}

.cis-disclaimer-container {
  height: 750px;
  margin: 5px 0;
  padding: 20px 15px;
  overflow-y: auto;
  background: #ccc;
  border: 1px solid #666;
}

.cis-disclaimer-container .checkbox-tips {
  font-weight: 700;
}

.cis-disclaimer-container .checkbox-item-wrapper {
  position: relative;
  margin-top: 11px;
  font-size: 11px;
}

.cis-disclaimer-container .checkbox-button {
  position: absolute;
  left: 0;
  vertical-align: middle;
}

.cis-disclaimer-container .checkbox-text {
  margin-left: 20px;
}

.disclaimer-terms-conditions-container {
  margin-top: 20px;
}

.acceptable-btn-info-container {
  height: 46px;
}

.terms-acceptable-area {
  margin-top: 30px;
}

.terms-acceptable-area button{
  width:100px !important;
  height:26px;
}

.cis-warning {
  text-align: center;
  display:block;
margin-top: 5px; 
}

/*For loading mask*/
.cis-loading {
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
z-index: 311;
}

.cis-loading .cis-loading-bg {
position: absolute;
z-index: 111;
top: 0;
left: 0;
bottom: 0;
right: 0;
background-color: #ccc;
filter: Alpha(Opacity = 50); /*IE7 fix*/
opacity: .5;
}

/* .cis-loading .by-loading {
z-index: 999;
position: absolute;
top: 50%;
right: 50%;
} */

.cis-loading[data-show=false] {
display: none;
}

.cis-documents-detail{
  margin:15px;
}

.cis-documents-detail .cis-document-title{
  font-size: 38px;
  color: #002d72;
}

.cis-documents-detail .cis-ticker-header{
  font-size: 12px;
  font-weight: 400;
  color: #caccce;
}

.cis-documents-detail .cis-ticker-body{
  font-size: 16px;
  font-weight: 100;
  color: #002d72;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
}

.document-name{
  color: #002d72;
  font-weight: 100;
  text-align: left;
  text-transform: capitalize;
  font-size: 24px;
}
.cis-dis-content .document-name{
  font-size: 15px;
}

.input-checkbox-terms{
  vertical-align: middle;
}

.document-files{
  padding: 14px 10px;
  overflow-y: auto;
  font-size: 13px;
}

.page-overview{
  display: flex;
  height: 100%;
  flex-direction: column;
}

.page-body{
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.cis-doc-container{
  padding: 15px 12px;
  margin: 5px 0;
  border: 1px solid #f4f4f4;
}

.cis-doc-container .single-doc-row{
  margin:5px 0;
  font-size:14px;
}

.index-ticker-container{
  margin:5px 0;
}

.cis-contactUs-title {
color:#333333;
font-size: 18px;
font-family: Arial, Helvetica, sans-serif;
}

.cis-contactUs-content{
color:#333333 !important;
}

.cis-contactUs-content a{
text-decoration:underline;
color:#1165e5 !important;
}

.cis-contactUs-area {
background-color:#EBEBEB;
padding:20px;
margin:14px 0;
}

.cis-nodata-display{
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  margin-top: 25%;
  color: rgb(151, 153, 155);
}


.utility-header{
  font-size: 0.95em;
  line-height: 150%;
  background: #333333;
  height: 5px;
  padding: 7px 0;
  text-align: right;
  line-height: 100%;
}

.cis-document-text {
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
text-decoration: none;
color: #1165E5 !important;
text-indent: 5px;
}

.cis-document-text:hover {
text-decoration: underline !important;
}

.discliamer-checkbox-content{
  margin: 10px 0 16px;
  font-size: 16px;
}

.discliamer-checkbox-content input{
  width:16px;
  height:16px;
}

.disclaimer-e-mail{
  margin-bottom:10px;
}

.disclaimer-e-mail input{
  margin-left:10px;
  width: 180px;
}

.disclaimer-e-mail input.email-error-class:focus{
  outline-color:#4D90FE;
}

.disclaimer-e-mail input.email-error-class{
  outline-color: red;
  outline-offset: -2px;
  outline-style: auto;
  outline-width: 5px;
}

.notification-red{
  color:#B60000;
}

/* viewer style start */

.viewer-container {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgb(82, 86, 89);
}

.viewer-container .viewer-toolbar {
  width: 100%;
  height: 48px;
  line-height: 48px;
  display: flex;
  background-color: rgb(50, 54, 57);
  box-shadow: 2px 2px 5px rgb(50, 54, 57);
  color: rgb(241, 241, 241);
}

.viewer-toolbar .viewer-title-container {
  flex: 50%;
}

.viewer-toolbar .viewer-title-container .viewer-title {
  padding-left: 20px;
  font-size: 1rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.viewer-toolbar .page-selector-container {
  flex: 50%;
  font-size: 0.8rem;
  font-weight: 700;
}

.viewer-toolbar .close-icon-container .close-icon {
  margin-right: 24px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}

.viewer-container .viewer-content-wrapper {
  width: 100%;
  height: 95%;
  overflow-y: auto; 
}

.viewer-container .viewer-content-container {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.cis-doc-home-container{
  width: 1200px;
  margin: auto;
  min-height: calc( 100% - 217px);
}

.cis-doc-loading{
  z-index: 999 !important;
  position: absolute !important;
  top: 50% !important;
  right: 50% !important;
  margin-right: inherit !important
}

section.cis-doc-content {
  margin-bottom: 50px
}
section.cis-dis-content{
  margin-bottom: 200px
}
.login-detail-indice{
  text-align: center;
  position: relative
  }
  
.login-detail-indice-region{
  border: 1px solid #95aabc;
  width: 200px;
  display: inline-block;
  border-radius: 4px;
  height: 22px;
  text-align: initial;
  padding-left: 5px;
  cursor: not-allowed;background: #ccc
}

.detail-indice-index-commentary{
  padding-bottom: 5px;
  font-size: 18px;
  color: #002D72;
}

.email-detail-disclaimer{
  margin-top: 20px
}

.citiRadarTable{
  color: #fff;
  border: 0.5px solid #333;
}

.citiRadarTable th{
  padding: 3px 6px
}

#calendar-svg{
  display:none;
}

/* .terms-acceptable-area .by-button.by-bluefill{
  background: #07c !important;
  border-color:#07c !important
}

.terms-acceptable-area .by-button.by-bluefill:hover{
  border-color:#07c !important;
  color: #07c !important
} */



/* viewer style end */
