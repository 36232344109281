
.cis-charting-coverContent.cis-charting-from{
    right:230px;

}

.cis-charting-coverContent.cis-charting-from, .cis-charting-coverContent.cis-charting-to {
    top:40px !important;
}

.question-mark{
    display: inline-block;
    width: 15px;
    text-align: center;
    vertical-align: top;
    position: absolute;
    right: 4px;
}
.question-mark:hover{
    cursor: pointer;
}
.composition-detail-page .react-grid-HeaderCell{
    background: #002d72  !important;
}
.composition-detail-page .react-grid-HeaderRow .widget-HeaderCell__value{
    background: #002d72  !important;
}
.header-formatter{
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
    word-break: break-all;
    overflow: hidden;
    padding-right: 23px;
}
.Blended{
    background: linear-gradient(to right,#3296FF , #002D72);
    background-repeat: no-repeat;

}
.Simulated{
    background-color: #3296FF;
}
.header-in-more-data{
    width: 100%;
    display: inline-block;
    position: relative;
}
.more-data .react-grid-HeaderCell-sortable{
    max-width: 100%
}
.more-data .react-grid-HeaderCell-sortable--descending .question-mark, .more-data .react-grid-HeaderCell-sortable--ascending .question-mark{
    right: 15px;
}