.blueberry.full-width{
    width: 100%;
    margin: 0;

    .cis-select-disclaimer{
        padding:0 20px;
    }
}

.orange{
    background: rgb(247, 148, 30);
}

.margin-bottom-15{
    margin-bottom: 15px;
}
