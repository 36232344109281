@import "../../style/commonColor.less";
.logoBar {
    height: 80px;
    background: linear-gradient(180deg, #00BDF2 0%, #00B3F0 20%, #0066B3 75%, #004785 100%);
    display: flex;
    align-items: center;
}

.logoBar .container {
    position: relative;
}

.logoBar .toggleBtn {
    display: none;
    float: left;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    outline: 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
    position: absolute;
    left: 0;
    top: -10px;
}

.logoBar .toggleBtn .navbar-toggler-icon {
    cursor: pointer;
    height: 60px;
    width: 60px;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
    display: inline-block;
}

.logoBar .toggleBtn .navbar-toggler-icon .hamburger {
    width: 60px;
    height: 60px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoBar .toggleBtn .navbar-toggler-icon .hamburger span:first-child {
    margin-top: -5px;
    width: 22px;
}

.logoBar .toggleBtn .navbar-toggler-icon .hamburger span:nth-child(2) {
    width: 22px;
}

.logoBar .toggleBtn .navbar-toggler-icon .hamburger span:last-child {
    margin-top: 5px;
}

.logoBar .toggleBtn .navbar-toggler-icon .x span:first-child {
    margin-top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}

.logoBar .toggleBtn .navbar-toggler-icon .x span:nth-child(2) {
    opacity: 0;
    width: 22px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}

.logoBar .toggleBtn .navbar-toggler-icon .x span:last-child {
    margin-top: 0;
    width: 22px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.logoBar .toggleBtn .navbar-toggler-icon .hamburger span {
    content: "";
    position: absolute;
    border-radius: 5px;
    height: 2px;
    width: 16px;
    background: @backgroundColor;
    opacity: 1;
    top: 49%;
    left: 14px;
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
}

.logoBar .logo {
    float: left;
}

.logoBar .brand {
    margin-top: 6px;
    margin-right: -5px;
}